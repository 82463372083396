/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "La semana pasada descubrí por casualidad el ", React.createElement(_components.a, {
    href: "http://www.yegor256.com/"
  }, "blog"), " de ", React.createElement(_components.a, {
    href: "http://www.yegor256.com/about-me.html"
  }, "Yegor Bugayenko"), ", un blog que en tan solo una semana ha cambiado mi forma de programar, me ha hecho tomar consciencia de cómo funciona la programación orientada a objetos y lo que es más importante ha afianzado los cimientos de la moral que vengo cultivando desde hace tiempo."), "\n", React.createElement(_components.p, null, "Uno de sus posts que mejor resume su forma de programar, es decir, la que él considera correcta tiene como título ”", React.createElement(_components.a, {
    href: "http://www.yegor256.com/2014/11/20/seven-virtues-of-good-object.html"
  }, "Seven Virtues of a Good Object"), "”, me llamó la atención concretamente el punto 2, ", React.createElement(_components.strong, null, "los objectos trabajan por contratos"), ", a estos contratos en programación les llamamos ", React.createElement(_components.strong, null, "interfaces"), ". Me atrajo sobre todo el momento en el que dice que ", React.createElement(_components.strong, null, "no debe interesarnos su origen, ni su sexo ni su religión"), ", cosa que enlaza correctamente con otro de sus posts en el que nos invita a ", React.createElement(_components.a, {
    href: "http://www.yegor256.com/2015/04/02/class-casting-is-anti-pattern.html"
  }, "no discriminar objetos por su clase"), ". Como una revelación todas las piezas del puzzle empezaron a encajar en mi mente, cuando dejas de valer por lo que eres empiezas a ser considerado por lo que haces, por tu fidelidad a la palabra dada, en una palabra por tu honor, no por aquel honor medieval rancio que no era más que una burla esencialista, si no al honor original ese por el que cumplimos con nuestras promesas."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Nota para programadores"), ": Creo que voy a empezar a valorar mi programación respecto a la honorabilidad de mis objetos. ¿La clase X crea objetos honorables? Cuando creamos un test, lo que estamos haciendo es esto mismo, creamos un objeto y comprobamos si es honorable. El honor empieza a formar parte de mi vocabulario técnico también."), "\n", React.createElement(_components.p, null, "Photo credit: ", React.createElement(_components.a, {
    href: "https://www.flickr.com/photos/gugugagaa/5378186359/"
  }, "Pratap Sankar"), " via ", React.createElement(_components.a, {
    href: "http://scandinavianchairs.com/"
  }, "Scandinavian"), " / ", React.createElement(_components.a, {
    href: "http://creativecommons.org/licenses/by/2.0/"
  }, "CC BY")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
